@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: "Poppins", sans-serif;
}
button {
  font-family: "Poppins", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  &.no-scroll {
    overflow: hidden;
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.form-control-holder {
  display: block;
  label {
    display: block;
    font-weight: normal;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    display: block;
    width: calc(100% - 2rem);
    height: 40px;
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  textarea {
    min-height: 60px;
    height: auto;
    padding: 1rem;
    font-size: 0.8rem;
  }
  select {
    display: block;
    width: calc(100%);
    height: 40px;
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.mobile-table-holder {
  margin-top: 20px;
  display: block;

  .mobile-table-item-list {
    padding: 10px;
    background-color: #eee;
    border-radius: 12px;
  }

  .mobile-table-item {
    padding: 12px;
    border: 1px solid #eee;
    border-radius: 12px;
    margin-bottom: 10px;
    background: #fafafa;

    &:last-child {
      margin-bottom: 0;
    }

    .mti-title {
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }

    .mti-value {
      .form-control-holder {
        padding: 0;

        input {
          margin-bottom: 0;
        }
      }
    }
  }
}

.sidebar-menu-toggler {
  display: none;
}

.only-mobile {
  display: none !important;
}

.sidebar {
  .logo-admin-link-wrapper {
    position: static !important;
    transform: initial !important;
  }
}

@media (max-width: 600px) {
  .only-desktop {
    display: none !important;
  }
  .only-mobile {
    display: block !important;
  }
  .sidebar-logo {
    position: relative;
  }
  .sidebar-menu-toggler {
    display: block;
    position: absolute;
    right: 0px;
    top: 3px;
    width: 30px;
    height: auto;
    cursor: pointer;
  }
  .logo-container {
    align-items: flex-start !important;
  }
  .sidebar-hide {
    .sidebar {
      width: 30px !important;

      ul {
        display: none !important;
      }

      .logo-admin-link-wrapper {
        display: none;
      }
    }
    .logo-container {
      display: none !important;
    }
    .admin-content {
      width: calc(100% - 30px) !important;
      padding: 1rem 1rem 0 1rem !important;
    }
    .sidebar-menu-toggler {
      top: -15px;
    }
  }
}

.html-editor {
  min-height: 350px;
  .fr-wrapper {
    min-height: 250px;
  }
}

.flowchart-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  align-items: stretch;
}

#campaigns-section .flowchart-container {
  width: calc(100% + 275px);

  @media (max-width: 1366px) {
    width: 100%;
  }
}

.flowchart-item {
  width: calc(33% - 30px);
  display: flex;
  flex-direction: column;
  box-shadow: 2px 3px 8px 0px #eee;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;

  &--img {
    height: 225px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--title {
    color: #f39200;
    font-size: 20px !important;
    margin-bottom: 10px !important;
    text-align: left;
  }

  &--text {
    color: #353f79;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    line-height: 21px;
  }
}

.flowchart-arrow {
  width: 40px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .flowchart-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .flowchart-item {
    width: 100%;
  }

  .flowchart-arrow {
    width: 100%;
    height: 40px;
    img {
      transform: rotate(90deg);
      margin-top: 0;
      max-height: 100%;
    }
  }
}

.owl-theme .owl-dots .owl-dot:hover span {
  background: #f39200;
  @media (max-width: 768px) {
    background: #d6d6d6;
  }
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #f39200;
}

body.eker,
body.scooter,
body.sportive,
body.elbisebul,
body.yasomi,
body.boost,
body.muhiku,
body.mydukkan,
body.itsbasic,
body.hummel,
body.hummel-2 {
  @media (max-width: 768px) {
    .holder.header-holder {
      padding: 0.5rem 1rem;

      .header-logo {
        height: 20px;
      }

      .mobile-menu {
        .menu-toggler {
          height: 20px;
        }
      }
    }

    .wp-button {
      right: 10px;
      bottom: 10px;
      padding: 10px 12px;
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .wp-button {
    right: 10px;
    bottom: 10px;
    padding: 10px 12px;
    span {
      display: none;
    }
  }
}

.holder.eker {
  a {
    color: #80754d;
    font-style: italic;
  }
}

.holder.sportive {
  a {
    color: #000;
    font-style: italic;
  }
}

.holder.scooter {
  a {
    font-style: italic;
    color: #e45b0c;
  }
}

.holder.elbisebul {
  a {
    font-style: italic;
    color: #000;
  }
}

.holder.yasomi {
  a {
    font-style: italic;
    color: #25b1dd;
  }
}

.holder.boost {
  a {
    font-style: italic;
    color: #000;
  }
}

.holder.muhiku {
  a {
    font-style: italic;
    color: #7378e9;
  }
}

.holder.mydukkan {
  a {
    font-style: italic;
    color: #56326c;
  }
}

.holder.itsbasic {
  a {
    font-style: italic;
    color: #7f344a;
  }
}

.holder.hummel {
  a {
    font-style: italic;
    color: #000;
  }
}

.holder.hummel-2 {
  a {
    font-style: italic;
    color: #444;
  }
}
